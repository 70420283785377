import React from 'react';

import Layout from '../components/Layout';
import dictionary from '../data/_dictionary';
import reviewers from '../data/_reviewers';
import getLatinized from '../lib/getLatinized';

const getPrepared = items => (
    items
        .sort((a, b) =>
            getLatinized(a.sort.toLowerCase()) >
            getLatinized(b.sort.toLowerCase()) ? 1 : -1
        )
        .reduce((acc, cur) => {
            if (!acc.length || acc[acc.length - 1].incipit !== getLatinized(cur.sort[0])) {
                acc.push({
                    incipit: getLatinized(cur.sort[0]),
                    subitems: [cur],
                });
            } else {
                acc[acc.length - 1].subitems.push(cur);
            }
            return acc;
        }, [])
);

const Reviewers = ({
    pageContext: {description, from, lang, title, url},
}) => (
    <Layout
        description={description}
        lang={lang}
        name='reviewers'
        path={from}
        title={title}
        url={url}
    >
        {<h2>{dictionary.reviewers[lang]}</h2>}
        {getPrepared(reviewers).map((item, i) => (
            <details close>
                <summary>{item.incipit}</summary>
                <ul>
                    {item.subitems.map((subitem, subi) => (
                        <li key={subi}>{subitem.label[lang]}</li>
                    ))}
                </ul>
            </details>
        ))}
    </Layout>
);

export default Reviewers;
